<template>
  <b-card body-class="p-1">
    <b-custom-tabs :tabs="tabs" lazy></b-custom-tabs>
  </b-card>
</template>

<script>
import InvoicePayments from "./InvoicePayments.vue";
import GroupedCalls from "../calls/GroupedCalls.vue";
import FinanceCalls from "../calls/FinanceCalls.vue";
export default {
  computed: {
    tabs() {
      return [
        { title: "Gelenler", component: InvoicePayments },
        { title: "Taahhütler" },
        { title: "Görüşmeler", component: this.$route.name === "finance-contact-detail" ? FinanceCalls : GroupedCalls, resource: "finance-calls" },
      ];
    },
  },
};
</script>

<style></style>
