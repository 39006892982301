<template>
  <app-overlay>
    <db-searchable-table v-model="filters" @changed="getData" :items="items" :fields="fields" :total-rows="total" searchable filterable>
      <template #modalbutton>
        <app-button @click="showCreateForm" text="Görüşme Ekle" icon="PlusIcon" size="md" />
      </template>
      <!--   <template #filtercollapse>
        <b-form-checkbox @change="getData" v-model="archived" :value="!archived"> Arşivdekileri Göster </b-form-checkbox>
      </template> -->
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item @click="showEndOfForm(item)"> <feather-icon icon="PlusIcon" class="mr-25" /> Yeni Görüşme </b-dropdown-item>
          <b-dropdown-item v-if="!selectedCompany && !selectedContact" @click="showContactDetails(item)">
            <feather-icon icon="UserIcon" class="mr-25" /> Müşteri Detayı
          </b-dropdown-item>
          <b-dropdown-item @click="deleteCall(item)"><feather-icon icon="TrashIcon" class="mr-25" /> Sil </b-dropdown-item>
          <b-dropdown-item @click="archiveItem(item)"><feather-icon icon="ArchiveIcon" class="mr-25" /> İşlemi Sonlandır </b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import EndOfCallForm from "@/views/Dashboard/TodaysCalls/EndOfCallForm.vue";
import CreateCallForm from "./CreateCallForm.vue";
export default {
  data() {
    return {
      items: [],

      total: 0,
      filters: {},
      archived: false,
    };
  },
  computed: {
    fields() {
      return [
        ...(!this.selectedCompany?.parasut_contact_id && !this.selectedContact?.contact_id
          ? [{ key: "company.name", label: "Firma", limit: 20, tdClass: "no-wrap", sortable: true }]
          : []),
        { key: "whenCalled", label: "Arama Tarihi", formatDate: true, sortable: true },
        { key: "nextCall", label: "Aranacak Tarih", formatDate: true, sortable: true },
        { key: "callee.fullname", label: "Aranan" },
        { key: "description", label: "Açıklama", limit: 20, tdClass: "no-wrap" },
        { key: "addedBy.fullname", label: "Kullanıcı" },
        { key: "status.label", label: "Sonuç" },
        { key: "actions", label: "İşlemler" },
      ];
    },
    selectedContact() {
      return this.$store.getters?.selectedParasutContact;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
  },
  methods: {
    showCreateForm() {
      this.$showAppSidebar("Görüşme Ekle", CreateCallForm, { callback: this.getData });
    },
    showEndOfForm(item) {
      this.$showAppSidebar("Görüşmeyi Sonlandır", EndOfCallForm, { data: { ...item }, callback: this.getData });
    },
    getData() {
      this.$axios
        .get("/finance/calls/list", {
          params: {
            ...this.filters,
            contact_id: this.selectedCompany?.parasut_contact_id ?? this.selectedContact?.contact_id,
            searchFields: JSON.stringify(["company.name", "callee.fullname"]),
          },
          loading: "table",
        })
        .then((result) => {
          this.items = result?.data?.data;
          this.total = result?.data?.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    archiveItem(item) {
      this.$confirm({ message: "Kayıt sonlandırılıp arşivlenecektir emin misiniz?" }, () => {
        this.$axios
          .patch(`/finance/calls/remove-next-call/${item?._id}`, {}, { loading: "table" })
          .then(() => {
            this.$emitter.$emit("Notification", { variant: "success" });
            this.getData();
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", message: err?.data?.message });
            console.log(err);
          });
      });
    },
    showContactDetails(item) {
      this.$router.push({ name: "finance-contact-detail", params: { id: item?.contact_id } });
    },
    deleteCall(item) {
      this.$confirm({ message: "Kayıt silinecektir emin misiniz?" }, () => {
        this.$axios
          .delete(`/finance/calls/${item._id}`, { loading: "table" })
          .then(() => {
            this.getData();
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", message: err?.data?.message, variant: "danger" });
          });
      });
    },
  },
};
</script>

<style></style>
