<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="createCallForm">
      <app-select-input
        v-if="!selectedCompany && !selectedContact"
        @input="getContacts"
        v-model="company"
        name="* Firma"
        :options="companyOptions"
        label="Firma:"
        placeholder="Firma Seçiniz..."
        select_label="name"
        rules="required"
        :loading="companyLoader"
      />
      <callee-auto-suggest-input v-model="data.callee" v-if="getContactId()" :contact_id="getContactId()" />

      <app-select-input v-model="data.status" name="Sonuç" :options="statusOptions" label="* Sonuç:" placeholder="Sonuç Seçiniz..." select_label="label" rules="required" />
      <app-textarea-input v-model="data.description" name="Açıklama" label="Açıklama:" placeholder="Açıklama..." />
      <app-date-input v-model="data.nextCall" name="Sonraki Arama Tarihi" label="Sonraki Arama Tarihi:" placeholder="Tarih Seçiniz..." />
      <hr />
      <app-button @click="createCall" text="Ekle" icon="PlusIcon" size="md" block />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import CalleeAutoSuggestInput from "./CalleeAutoSuggestInput.vue";
export default {
  components: { ValidationObserver, CalleeAutoSuggestInput },
  data() {
    return {
      data: {
        description: "",
        whenCalled: new Date(),
        callee: null,
        status: null,
      },
      companyLoader: false,
      contactLoader: false,
      company: null,
      companyOptions: [],
      contactOptions: [],
      statusOptions: [
        { key: "called", label: "Görüşüldü" },
        { key: "not_reached", label: "Ulaşılamadı" },
        { key: "payment_promise", label: "Ödeme Sözü" },
        { key: "payment_rebuilded", label: "Yapılandırma" },
        { key: "legal_proceeding", label: "Yasal Takip" },
        { key: "other", label: "Diğer" },
      ],
    };
  },
  computed: {
    callback() {
      return this.$store.getters?.appSidebarItem?.callback;
    },
    selectedContact() {
      return this.$store.getters?.selectedParasutContact;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
  },
  methods: {
    getContactId() {
      return this?.company?.contact_id ?? this?.selectedContact?.contact_id ?? this?.selectedCompany?.parasut_contact_id;
    },
    setData() {
      return {
        ...this.data,
        whenCalled: new Date(this?.data?.whenCalled),
        nextCall: new Date(this?.data?.nextCall),
        company: {
          name: this.company?.name ?? this?.selectedContact?.name ?? this?.selectedCompany?.name,
          taxNo: this.company?.taxNo ?? this?.selectedContact?.taxNo ?? this?.selectedCompany?.taxNo,
        },
        contact_id: this?.company?.contact_id ?? this?.selectedContact?.contact_id ?? this?.selectedCompany?.parasut_contact_id,
      };
    },
    createCall() {
      this.$validate(this.$refs.createCallForm, () => {
        this.$axios
          .post("/finance/calls/create", this.setData(), { loading: "sidebar" })
          .then(() => {
            this.callback && this.callback();
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", variant: "success" });
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", message: err?.data?.message, variant: "danger" });
          });
      });
    },
    getCompanyOptions() {
      this.companyLoader = true;

      this.$axios
        .get("/finance/parasut-companies/options")
        .then((result) => {
          this.companyOptions = result.data;
          this.companyLoader = false;
        })
        .catch((err) => {
          this.companyLoader = false;
          console.log(err);
        });
    },
    getContacts(item) {
      this.contactOptions = [];
      if (item) {
        this.contactLoader = true;
        this.$axios
          .get("/company/contacts/all", { params: { parasut_contact_id: item?.contact_id } })
          .then((result) => {
            this.contactOptions = result.data;
            this.contactLoader = false;
          })
          .catch((err) => {
            this.contactLoader = false;
            console.log(err);
          });
      }
    },
    formatPhoneNumber(number) {
      let digits = number.replace(/\D/g, "");

      if (digits.length === 11) {
        return digits.replace(/(\d{4})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4");
      } else {
        return "Invalid phone number";
      }
    },
  },
  mounted() {
    this.getCompanyOptions();
  },
};
</script>
